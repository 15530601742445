import { useAuthStore } from "~/store/auth";

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const { $toast } = useNuxtApp();
  if (process.client) {
    if (!authStore.company?.has_stripe_customer) {
      $toast.error("Aucun client Stripe attaché à votre compte");
      return navigateTo("/dashboard");
    }

    if (!authStore.company?.has_stripe_account) {
      $toast.error("Vous n'avez pas de compte Stripe");
      return navigateTo("/dashboard");
    }

    if (!authStore.company?.onboarding_completed) {
      $toast.error("Votre compte Stripe est incomplet");
      return navigateTo("/dashboard");
    }
  }
});
